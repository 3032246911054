import React, { useState } from "react";
import Layout from "../components/layout";
import Container from "../components/container.js";
import Seo from "../components/seo";
import { Heading } from "../components/typo";
import { LocalizedLink } from "gatsby-theme-i18n";
import { navigate } from "gatsby";
import { useIntl } from "react-intl";
import { Box } from "grommet";
import { AmplifyAuthenticator, AmplifySignIn } from "@aws-amplify/ui-react";

import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";

const UserPage = ({ pageContext }) => {
  const intl = useIntl();
  const locale = pageContext.locale;
  const originalPath = pageContext.originalPath;

  const [authState, setAuthState] = useState();
  const [user, setUser] = useState();

  React.useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
    });
  }, []);

  if (authState === AuthState.SignedIn && user) {
    navigate("/passport/");
  }

  return (
    <Layout locale={locale} originalPath={originalPath}>
      <Seo title={intl.formatMessage({ id: "page-program" })} />
      <Container>
        <Heading level="1" textAlign="center">
          {intl.formatMessage({ id: "page-user" })}
        </Heading>
        <AmplifyAuthenticator>
          <AmplifySignIn
            hideSignUp
            usernameAlias="email"
            slot="sign-in"
          ></AmplifySignIn>
        </AmplifyAuthenticator>
        <Box align="center">
          <div>
            {intl.formatMessage({ id: "no-account" }) + " "}
            <LocalizedLink to="/registration/">
              {intl.formatMessage({ id: "no-account-then-register" })}
            </LocalizedLink>
          </div>
        </Box>
      </Container>
    </Layout>
  );
};

export default UserPage;
